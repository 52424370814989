<template>
  <div class="student-manage">
    <div class="search-box">
      <Input style="width: 300px;margin-right: 20px;" v-model="searchForm.keyword" placeholder="搜索学生姓名、昵称、手机号" clearable
        search :enter-button="true" @on-search="getList" />
      <el-select style="width: 300px;" v-model="searchForm.group_id" placeholder="选择班级筛选" clearable size="small"
        filterable remote :remote-method="getClassList" :loading="loading" @change="getList()">
        <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
    </div>
    <el-table style="margin-top: 20px;" :data="tableData" size="small" border>
      <el-table-column label="ID" prop="id"></el-table-column>
      <el-table-column label="所属租户" prop="site.name"></el-table-column>
      <el-table-column label="专业岗位" prop="siteUser.department"></el-table-column>
      <el-table-column label="昵称" show-overflow-tooltip>
        <template v-slot="{ row }">
          <router-link style="color:#2db7f5;"
            :to="{ name: isSuper ? 'user/detail' : 'manager/user/detail', params: { id: row.id, tab: 'index' } }">
            {{ row.nickname }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="真实姓名" prop="realname" show-overflow-tooltip></el-table-column>
      <el-table-column label="手机号" prop="mobile" show-overflow-tooltip></el-table-column>
      <el-table-column label="状态" prop="status">
        <template v-slot="{ row }">
          <div>{{ statusObj[row.status] }}</div>
        </template>
      </el-table-column>
      <el-table-column label="星级评分" prop="score"></el-table-column>
      <el-table-column label="注册和更新时间" show-overflow-tooltip>
        <template v-slot="{ row }">
          <div>{{ util.timeFormatter(new Date(+row.created_at * 1000), 'yy-MM-dd hh:mm') }}</div>
          <div>{{ util.timeFormatter(new Date(+row.updated_at * 1000), 'yy-MM-dd hh:mm') }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120">
        <template v-slot="{ row }">
          <el-button size="small" type="warning" icon="el-icon-edit" @click="edit(row)"></el-button>
          <el-button size="small" type="danger" icon="el-icon-delete" @click="dele(row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagin style="margin-top: 20px;text-align: end;" :pagination="pagination" @currentChange="currentChange"
      @sizeChange="sizeChange" />
    <CreateUser :isManager="isManager" :userModal="userModal" :userId="userId" @close="closeModal"></CreateUser>
  </div>
</template>

<script>
import util from "@/utils/tools.js";
import Pagin from "@/components/myPagin.vue";
import CreateUser from '@/components/createUser';
import { MessageBox } from "element-ui";
export default {
  name: "StudentManage",
  props: {
    isUser: {
      default: false,
      type: Boolean
    },
    isSuper: {
      type: Boolean,
      default: false
    },
    isManager: {
      default: false,
      type: Boolean
    }
  },
  components: {
    Pagin,
    CreateUser
  },
  data() {
    return {
      util,
      searchForm: {
        keyword: "",
        group_id: ""
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      rolesList: [],
      statusObj: {},
      statusList: [],
      userModal: false,
      userId: "",
      classList: [],
      loading: false
    }
  },
  methods: {
    getList() {
      const { searchForm, pagination } = this;
      this.api.user.userList({
        site_id: this.isSuper ? "-1" : "0",
        user_id: "-1",
        is_with_last_follow: 1,
        with_score: 1,
        role: "role_student",
        ...searchForm,
        ...pagination,
      }).then((res) => {
        this.statusObj = res.statuses;
        this.tableData = res.list || [];
        pagination.total = Number(res.count);
      });
    },
    currentChange(page) {
      const { pagination } = this;
      pagination.page = page;
      this.getList();
    },
    sizeChange(size) {
      const { pagination } = this;
      pagination.pageSize = size;
      this.getList();
    },
    edit(row) {
      this.userId = row.id;
      this.userModal = true;
    },
    dele(row) {
      MessageBox.confirm('确定删除当前数据吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.api.user.userDelete({
          user_id: row.id
        }).then(() => {
          this.$Message.success("删除成功");
          this.getList();
        });
      });
    },
    closeModal() {
      this.userModal = false;
      this.getList();
    },
    getClassList(keyword) {
      this.loading = true;
      this.api.user.groupList({
        user_id: "-1",
        page: 1,
        pageSize: 20,
        keyword
      }).then(res => {
        this.classList = res.list || [];
        this.loading = false;
      });
    }
  },
  created() {
    this.getList();
    this.getClassList();
  }
}
</script>

<style lang="scss" scoped>
.student-manage {
  background-color: white;

  .search-box {
    display: flex;
    align-items: center;
  }
}
</style>