<template>
  <div class="role-main">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="role-main-cont">
      <div class="top">
        <div class="tab">
          <p :class="curTabId == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item.id)">{{ item.name }}</p>
        </div>
        <div>
          <Button type="primary" @click="associationDrawer=true" style="margin-right: 20px;">创建班级</Button>
          <Button type="primary" @click="add" style="margin-right: 20px" >{{$t('user_add')}}</Button>
          <Button type="primary" @click="addInvite">链接/二维码邀请</Button>
        </div>
      </div>

      <!-- <Group :isManager="true" :selectedClass="selectedClass" v-if="curTabId == 1 && !loading"></Group> -->
      <StudentManage :isUser="true" :isManager="true" v-if="curTabId==='1'"/>
      <TeacherManage :isUser="true" :isManager="true" v-if="curTabId==='2'"/>
      <!-- <UserList :isUser="true" :isManager="true" v-if="curTabId == 4 && !loading"></UserList> -->
      <ClassManage :isUser="true" :isManager="true" :refresh="refresh" v-if="curTabId == 3"></ClassManage>
    </div>

    <el-drawer :visible.sync="modalStatus" :size="800" @close="cancel">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">链接/二维码邀请</div>
        <div class="drawer-title-btns"></div>
      </div>
      <div style="padding: 20px;">
        <InviteModal v-if="modalStatus" :rolesList="rolesList" :expireList="expireList" :siteId="$store.state.user.userInfo.site.id" :siteName="$store.state.user.userInfo.site.name" :isManager="true"></InviteModal>
      </div>
    </el-drawer>

    <!-- <div v-if="userModal"> -->
      <CreateUser :isManager="isManager" :userModal="userModal" @close="closeModal"></CreateUser>
    <!-- </div> -->
    <el-drawer :visible.sync="drawerShow" :size="800" @close="drawerClose()">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">{{ addEditForm.group_id ? "编辑班级" : "创建班级" }}</div>
        <div class="drawer-title-btns">
          <Button @click="drawerShow = false" style="margin-right: 10px;">取消</Button>
          <Button type="primary" @click="doOk()">确定</Button>
        </div>
      </div>
      <div style="padding: 20px;">
        <el-form ref="formRefs" :model="addEditForm" label-width="80px" size="small" :rules="rules">
          <el-form-item label="班级名称" prop="name">
            <el-input v-model="addEditForm.name" placeholder="请输入班级名称"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <AssociatedData :show="associationDrawer" @close="associationDrawerClose"/>
  </div>
</template>

<script>
// import UserList from './components/userList'
// import Group from './components/group'
import InviteModal from '@/components/inviteModal.vue';
import CreateUser from '@/components/createUser';
import StudentManage from "./components/studentManage.vue";
import TeacherManage from "./components/teacherManage.vue";
import ClassManage from './components/classManage.vue';
import AssociatedData from './components/associatedData.vue';
export default {
  name: "organizationalStructure",
  data(){
    return{
      associationDrawer:false,
      tabList:[
        {
          name:"学生管理",
          id:"1"
        },
        {
          name:"老师管理",
          id:"2"
        },
        {
          name:"班级管理",
          id:"3"
        },
        // {
        //   name:'人员管理',
        //   id:'4'
        // },
        // {
        //   name:'班级学员',
        //   id:'5'
        // }
      ],
      curTabId:'1',
      modalStatus:false,
      expireList:[],
      rolesList:[],
      userModal:false,
      loading:false,
      isManager:true,
      selectedClass:0,
      addEditForm:{
        name: "",
      },
      drawerShow:false,
      rules: {
        name: [
          { required: true, message: "请输入班级名称", trigger: "blur" }
        ]
      },
      refresh:Math.random()
    }
  },
  components:{
    // UserList,
    // Group,
    InviteModal,
    CreateUser,
    StudentManage,
    ClassManage,
    TeacherManage,
    AssociatedData
  },
  created(){
    this.getInvitationForm();
  },
  methods:{
    changeTab(id){
      this.curTabId = id;
    },
    getInvitationForm(){
      this.api.site.invitationForm().then((res)=>{
        console.log(res,"formformform");
        this.expireList = [];
        for(let name in res.expire_types){
          this.expireList.push({
            label: res.expire_types[name],
            value: name
          });
        }
        this.rolesList = [];
        for(let name in res.invitation_roles){
          this.rolesList.push({
            label:res.invitation_roles[name],
            value:name
          })
        }

      })
    },
    addInvite(){
      this.modalStatus = true;
    },
    cancel(){
      this.modalStatus = false;
    },
    add(){  //创建用户
      this.userModal = true;
    },
    closeModal(){
      this.userModal = false;
      this.loading = true;
      this.$nextTick(()=>{
        this.loading = false;
      })
    },


    doOk() {
      const { addEditForm } = this;
      this.$refs.formRefs.validate(valid => {
        if (valid) {
          this.api.user.groupCreate(addEditForm).then(() => {
            this.$Message.success("创建班级成功");
            this.refresh=Math.random();
            this.drawerShow = false;
          });
        }
      });
    },
    drawerClose() {
      this.addEditForm = {
        name: "",
      }
      this.$refs.formRefs.resetFields();
    },
    associationDrawerClose(){
      this.refresh=Math.random();
      this.associationDrawer=false;
    }
  }
}
</script>

<style scoped lang="scss">
.role-main{
  margin: 20px;

  .role-main-cont{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;

    .tab{


      display: flex;
      justify-content: flex-start;
      align-items: center;
      >p{
        margin-right: 30px;
        padding-bottom: 10px;
        height: 100%;
        font-size: 16px;
        color: #666666;
        cursor: pointer;
      }
      .active{
        font-weight: bold;
        color: #4A79FF;
        border-bottom: 4px solid #5782FF;
      }
    }
  }

  .margin-bottom-10{
    margin-bottom: 20px;
  }
  .top{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EEEEEE;
  }
}
</style>
